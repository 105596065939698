$(document).ready(initPage);

function initPage(){
  $('[data-navmenu]').click(function(){
    var activeId = $(this).data('navmenu');

    $('.navmenu-level.active').removeClass('active').addClass('hiding');
    $('#' + activeId).addClass('active');
    
    $('.header-menu.active').removeClass('active');
    $('.header-menu[data-menu=' + activeId + ']').addClass('active');

    $('.header-title-item.active').removeClass('active');
    $('.header-title-item[data-title=' + activeId + ']').addClass('active');

    setTimeout(function(){
      $('.navmenu-level').removeClass('hiding');
    }, 500);

  });


  $('.tab:last-child').addClass('last');


  $('.tab-txt').each(function(){ 
    const pstxt = new PerfectScrollbar($(this)[0], {
      // wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20,
      useBothWheelAxes: true
    });
  });

  $('.navmenu-inner').each(function(){
    new ScrollBooster({
      viewport: $(this)[0],
      content: $(this).find('.tabs')[0],
      scrollMode: 'native',
      direction: 'horizontal',
      emulateScroll: true,
      onClick: (state, event, isTouchDevice) => {
        if (Math.abs(state.dragOffset.x) < 10) {
          var clickedTab = $(event.target).is('[data-navmenutab]') ? $(event.target)[0] : $(event.target).parents('[data-navmenutab]')[0];
          
          if(clickedTab) {
            var activeId = clickedTab.dataset.navmenutab;

            $('.navmenu-level.active').removeClass('active').addClass('hiding');
            $('#' + activeId).addClass('active');
            
            $('.header-menu.active').removeClass('active');
            $('.header-menu[data-menu=' + activeId + ']').addClass('active');

            $('.header-title-item.active').removeClass('active');
            $('.header-title-item[data-title=' + activeId + ']').addClass('active');

            setTimeout(function(){
              $('.navmenu-level').removeClass('hiding');
            }, 500);
          }
        }
      }
    });
  });

  $('.chapters .toggler').click(function(){
    $('.chapters').toggleClass('expanded');
  });
  $('.chapters .chapters-list a').click(function(){
    $('.chapters').removeClass('expanded');
  });
}